@import "~antd/dist/antd.css";

.mainPage {
  display: flex;
}

.signInPage {
  width: 40%;
  margin: 0 auto;
  max-width: 800px;
}

.mainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
}

.formFields {
  width: 80%;
  margin: 0 auto;
}

.sidebarImg > img {
  height: 100vh;
}

.twitterButton {
  background-color: #1DA1F2;
}

.facebookButton {
  background-color: #4267B2;
}
