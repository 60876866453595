body {
  background-color: #fbfbfb;
}

.avatar {
  border: 2px solid black;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}
.row {
  display: flex;
}

.row .col-1 {
  flex: 1;
  padding: 0px 20px;
}

.row .col-2 {
  flex: 2;
  padding: 0px 20px;
}

.uploadBox p {
  font-size: 10px;
  line-height: 17px;
  text-align: left;
  letter-spacing: -0.03em;
  color: #8193A0;
  margin: 0px;
}

.uploadBox h5 {
  font-size: 11px;
  letter-spacing: -0.03em;
  color: #111111;
  margin: 0px;
  margin-bottom: 5px;
}

.uploadBox span {
  font-weight: normal;
  font-size: 8px;
  letter-spacing: -0.03em;
  color: #8193A0;
}

.createNewSeries {
  color: #34A9FF;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
}

.uploadBox {
  /* max-height: 146px; */
  height: auto !important;
  padding: 0px 23.7px;
  border: none !important;
  background-color: #F8FAFB !important;
  border: 2px dashed #DFE3E8 !important;
  overflow: hidden;
  position: relative;
}

.text-center {
  text-align: center;
}

.header {
  flex-direction: column;
  background-color: var(--background-primary);
  height: 332px;
  display: flex;
  align-items: center;
  padding: 32px 10px;
  .logo {
    margin-bottom: 30px;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin: 0px;
  }
  .subscribers {
    display: inline-flex;
    margin-top: 10px;
  }
  .subscribers-count {
    margin: 0;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    display: block;
    margin-bottom: -5px;
  }
  span {
    font-weight: normal;
    font-size: 10px;
    letter-spacing: -0.03em;
    color: rgba(255, 255, 255, 0.6);
  }
}

.uploadForm {
  display: flex;
  flex-direction: column;
}

.tagline {
  width: 50%;
}

.blueText {
  color: var(--background-primary);
}

.uploadLightBtn {
  width: 30%;
  margin-left: 5%;
}

.libraryTitleContainer {
  display: flex;
}

.libraryTitleImage {
  margin-right: 10px;
  /* TODO: removed this but can be added to follow design */
  /* padding-top: 6px; */
}

.libraryTitle {
  color: #289DF3;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.libraryPublishedTag {
  background-color: #CAEFDB;
  padding: 10px 25px;
  border-radius: 10px;

}

.libraryDraftTag {
  background-color: #E9EBEE;
  padding: 10px 25px;
  border-radius: 10px;
}

.analyticsPageStreamSummaryCard {
  display: flex;
  padding-left: 10px;
}
.analyticsStreamSummary {
  margin-left: 20px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #737A91;
    margin: 0;
  }
  .analyticsStreamSummaryData {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.3px;
    color: #111111;
  }
}


.analyticsCategoryLegend {
  display: flex;
}

.analyticsCategoryLegendColor {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.text-right {
  text-align: right;
}