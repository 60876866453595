.ant-modal-content {
    .ant-modal-header {
        border-bottom: none;
    }
    .ant-modal-body {
        .ant-input {
            font-size: 14px;
            height: 49px;
            width: 100% !important;
            border-radius: 3px;
          }
          .ant-select .ant-select-selector { 
            font-size: 14px;
            height: 49px;
            border-radius: 3px;
            padding: 0px 8px;
          }
          textarea.ant-input {
            height: 99px
          }
          .ant-upload-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
          }
          .ant-form-item-label {
            padding: 0px;
            padding-bottom: 8px;
            label {
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
            }
          }
          .ant-form-large .ant-form-item-label > label {
            height: auto;
          }
          .btn-action {
              width: max-content;
              padding: 0px 51px;
          }
          .uploader {
              margin-bottom: 50px;
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 29px;
                letter-spacing: -0.03em;
                color: #111111;
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 30px;
                letter-spacing: -0.03em;
                color: #8193A0;
              }
          }
    }
    .ant-modal-footer {
        border-top: none;
    }
} 