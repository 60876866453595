@import '~antd/dist/antd.css';

a {
  text-decoration: none;
}

a:active {
  color: transparent;
}

a:hover {
  font-weight: normal;
  /* color: transparent; */
}

a[tabindex]:focus {
  color:transparent;
  outline: none;
}

/* BOTTOM CTA */
.bottomButtonsWrapper {
  width: 80% !important;
  margin: 0 auto !important;
  text-align: right !important;
}

.bottomCTA {
  width: 30% !important;
  margin-left: 5% !important;
}

:root {
  --background-primary: #289DF3;
}